.accordionOne {
  padding: 0px;
  box-shadow: none !important;
}

.accordionSummaryTest>div:nth-child(2) {
  color: green;
}

.imgSideBar {
  width: "36px";
  height: "36px";
}


.test {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.mainAcordianDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  margin-top: 12px;
}

.accordionDetailsHeading {
  display: flex;
  align-items: center;
  gap: 12px;
}

.detailsContainer {
  margin: 10px 0 0 35px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.subDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.accordionSubDetailsThree {
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  margin-top: 10px;
  margin-left: 35px;
}

.subCategoryDetails {
  display: flex;
  margin: 6px 0 0 35px;
}

.subAccordionSubDetailsThree {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  margin-top: 6px;
}

.sideBarToggle {
  position: fixed;
  z-index: 9;
  width: 35vw;
  height: 100%;
}

.MeasurementIcon {
  width: 24px;
}